import React from 'react';

import '../../styles.css';
import SEOComponent from '../components/fields/seoField';
import CommonPageRenderer from '../components/pageRendererHoc/CommonPageRenderer';

const ResponsibleGaming = () => {
	return (
		<div className='static-page responsibleGaming'>
			<CommonPageRenderer>
				<SEOComponent
					title='Responsible Gaming - Snakes & Ladders Plus'
					description='Responsible Gaming We at Zupee actively promote responsible game play for our users. We believe that gaming is and should be done purely for entertainment &#8211; meaning, playing till it doesn’t affect your body, mind or finances in a negative way. We strongly believe that with the right approach to our games, the experience can [...]'
				/>
				<div className='page-content mainContainer'>
					<h2 class='page-heading align-center'>Responsible Gaming</h2>
					<p className='description'>
						We at Zupee actively promote responsible game play for our users. We believe that gaming is and should be done
						purely for entertainment – meaning, playing till it doesn’t affect your body, mind or finances in a negative
						way. We strongly believe that with the right approach to our games, the experience can be highly rewarding.
						Games at Zupee not only give you the thrill of winning against players from across the country but can also
						improve your strategic prowess, decision making, and time-management skills.
					</p>
					<p className='description'>
						We at Zupee want you to enjoy our games as much as we have enjoyed creating them for you. As a responsible
						gaming company, we take the following steps for all our users:
					</p>
					<ul class='sub-page-ul'>
						<li>
							We comply with all applicable law, rules, regulations, and requirements necessary to run a real-money
							gaming application and structure our games with a view to being compliant.
						</li>
						<li>
							We only allow users who are 18+ to join the game and deploy screening and age-gating techniques and/or
							obtaining declarations from users as to their age.
						</li>
						<li>
							Game rules are explained in detail at the start of the game. They are also accessible as a video or in
							text format all the time within the app
						</li>
						<li>
							We provide 24X7 customer care at{' '}
							<a class='sub-page-link' href='mailto:support@snakesandladdersplus.com'>
								support@snakesandladdersplus.com
							</a>
						</li>
						<li>We offer multiple tournament options to choose from according to one’s risk appetite</li>
					</ul>
					<p className='description'>
						We understand that gaming has the potential to be addictive, and one can lose track of time and money whilst
						playing games. But keeping the following DOs and DON’Ts in mind, you can ensure your love for gaming doesn’t
						change into an addiction:
					</p>
					<h3 class='page-subheading'>Do’s</h3>
					<ul class='sub-page-ul'>
						<li>
							Play for entertainment alone, not as a source of earnings or remuneration which in any manner impacts your
							financial independence.
						</li>
						<li>
							Decide in advance how much money you will spend playing the game and only play with disposable income
							(i.e. such amounts of money you can afford to risk losing entirely without in any way jeopardizing your
							current financial position
						</li>
						<li>
							Decide on the number of hours you will be devoting to the game and stick to it ensuring you do not exceed
							such number of hours in any circumstances
						</li>
						<li>
							Set deposit limits while playing so that you never deposit more than your disposable income which you can
							afford to lose
						</li>
						<li>Take a break and pause playing for a while if you have lost money continuously multiple times</li>
						<li>
							Reach out to our customer care if you think you are addicted to any of our games and would like us to help
							you with dealing with your game-addiction
						</li>
					</ul>
					<h3 class='page-subheading'>Dont’s</h3>
					<ul class='sub-page-ul'>
						<li>Don’t play to earn extra income</li>
						<li>Don’t play impulsively</li>
						<li>Don’t play with loaned money</li>
						<li>
							Don’t play if you’re not in a calm mental state. This affects your game performance ultimately leading you
							to lose money
						</li>
						<li>
							Don’t chase losses. Since all games on Zupee are skill-based, take time to hone your skills to play better
						</li>
						<li>Don’t forget to keep a check on how much you are spending on the game</li>
						<li>
							Don’t play in between work or other engagements so you can play the game with a free mind. This is also
							important as you don’t want this to interfere with your day-to-day life
						</li>
						<li>
							Don’t harass, threaten or abuse other players on the Platform or engage in any conduct that would be
							considered as illegal, unethical or defamatory
						</li>
					</ul>
					<p className='description'>
						If you need any further information or help, write to us at{' '}
						<a class='sub-page-link' href='mailto:support@snakesandladdersplus.com'>
							support@snakesandladdersplus.com
						</a>{' '}
						and we would be happy to help.
					</p>
				</div>
			</CommonPageRenderer>
		</div>
	);
};

export default ResponsibleGaming;
